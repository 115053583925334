import React from 'react'
import * as S from './styles'
import { CrediarioDigitalSectionProps } from '../../types'
import SectionComponent from 'src/components/SectionComponent'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { CrediarioDigitalH2 } from '../../styles'
import { InterP } from 'src/components/Text/BodyText'
import { ButtonWithDeepLink } from '../components/_ButtonWithDeepLink'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import { useQRCodeModal } from 'src/context/QRCodeModalContext/QRCodeModalContext'

type SimplesComprarParceladoProps = CrediarioDigitalSectionProps

const TEXT_CONTENT = {
  heading: 'É simples comprar parcelado no Crediário',
  body: 'Com o Crediário Digital, você não precisa do cartão de crédito pra comprar parcelado. É só seguir o passo a passo abaixo:',
  listItems: [
    'Entre no <strong>Shopping do Inter</strong> pelo Super App',
    'Selecione o produto que deseja',
    'Clique em comprar',
    'Escolha <strong>Crediário Digital</strong> como forma de pagamento',
    'Defina a quantidade de parcelas',
    'Finalize a compra e pronto!',
  ],
  cta: 'Aproveitar agora',
}

const SimplesComprarParcelado = ({ id, width, dataLayerSection, sendDataLayerEvent }: SimplesComprarParceladoProps) => {
  const url = 'https://inter-co.onelink.me/Qyu7/0m9kefgw'
  // eslint-disable-next-line @typescript-eslint/typedef
  const { setIsModalOpen } = useQRCodeModal()

  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })

    if (width >= WIDTH_MD) {
      setIsModalOpen(true)
    }
  }
  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '944px', md: '499px', lg: '675px', xl: '691px', xxl: '854px' }}
    >
      <div className='col-12 col-md-6 order-md-2'>
        <S.TextContent>
          <CrediarioDigitalH2 color={orange.extra}>{TEXT_CONTENT.heading}</CrediarioDigitalH2>
          <InterP>{TEXT_CONTENT.body}</InterP>
          <ul className='list'>
            {TEXT_CONTENT.listItems.map((item, index) => (
              <li className='list-item' key={item.trim().toLocaleLowerCase() + index}>
                <span className='list-item__index'>{index + 1}</span>
                <p className='list-item__text' dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>
          {width >= WIDTH_MD && (
            <S.CtaBox>
              <ButtonWithDeepLink renderDeepLinkWhen={width < WIDTH_MD} url={url} onClick={handleCtaClick} text={TEXT_CONTENT.cta} />
            </S.CtaBox>
          )}
        </S.TextContent>
      </div>
      <div className='col-12 col-md-6 order-md-1'>
        <S.ImageBox>
          <video
            key='introVideo'
            height='auto'
            width='100%'
            loop
            muted
            autoPlay
            playsInline
            data-autoplay-desktop='true'
            data-autoplay-portrait='true'
            data-autoplay-mobile='true'
            data-play-on-hover='false'
            src='https://central-imagens.bancointer.com.br/gif/crediario-gif/arquivo.webm'
            preload='auto'
          >
            <source
              id='videoWebm'
              type='video/webm'
              src='https://central-imagens.bancointer.com.br/gif/crediario-gif/arquivo.webm'
            />
          </video>
        </S.ImageBox>
        {width < WIDTH_MD && (
          <S.CtaBox>
            <ButtonWithDeepLink renderDeepLinkWhen={width < WIDTH_MD} url={url} onClick={handleCtaClick} text={TEXT_CONTENT.cta} />
          </S.CtaBox>
          )}
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(SimplesComprarParcelado)
