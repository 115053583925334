import React from 'react'
import * as S from './styles'
import { CrediarioDigitalSectionProps } from '../../types'
import SectionComponent from 'src/components/SectionComponent'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { CrediarioDigitalH2 } from '../../styles'
import { ColoredTag } from '../components/_ColoredTag'
import { orange, secundaryGreen } from 'src/styles/newColors'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { DarkBoxGraphs, DarkBoxWithGraph } from '../components/DarkBoxWithGraph/_DarkBoxWithGraph'
import classnames from 'classnames'
import { ButtonWithDeepLink } from '../components/_ButtonWithDeepLink'
import { WIDTH_MD, WIDTH_SM } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import { useQRCodeModal } from 'src/context/QRCodeModalContext/QRCodeModalContext'

type ComoFuncionaAEntradaProps = CrediarioDigitalSectionProps

const TEXT_CONTENT = {
  heading: 'Como funciona a entrada e o parcelamento do Crediário Digital',
  tag: 'Fácil, prático e seguro!',
  carouselItems: [
    {
      title: 'Entrada',
      body: 'Ao finalizar sua compra, o valor da entrada é descontado direto da sua conta corrente.',
      graph: DarkBoxGraphs['coin-arrow-up-out'],
    },
    {
      title: 'Parcelas',
      body: 'As demais parcelas começam a ser descontadas apenas no mês seguinte.',
      graph: DarkBoxGraphs['coin-ellipsis'],
    },
    {
      title: 'Vencimento',
      body: 'Não precisa pagar boleto! Basta manter o valor disponível em conta e a parcela será paga automaticamente na data estabelecida.',
      graph: DarkBoxGraphs['coin-shield'],
    },
  ],
  cta: 'Conferir meu Crediário',
}

const ComoFuncionaAEntrada = ({ id, width, sendDataLayerEvent, dataLayerSection }: ComoFuncionaAEntradaProps) => {
  const url = 'https://inter-co.onelink.me/Qyu7/0m9kefgw'
  // eslint-disable-next-line @typescript-eslint/typedef
  const { setIsModalOpen } = useQRCodeModal()

  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })

    if (width >= WIDTH_MD) {
      setIsModalOpen(true)
    }
  }
  return (
    <SectionComponent
      id={id}
      bgColor={orange.extra}
      minHeight={{ sm: '505px', md: '485px', lg: '619px', xl: '723px', xxl: '852px' }}
    >
      <div className='col-12'>
        <S.TextContent>
          <CrediarioDigitalH2 color='#fff'>{TEXT_CONTENT.heading}</CrediarioDigitalH2>
          <ColoredTag text={TEXT_CONTENT.tag} backgroundColor={secundaryGreen} borderRadius='36px' />
        </S.TextContent>
      </div>
      <div className='col-12'>
        <S.CarouselBox>
          <ComoFuncionaAEntrada.ListView>
            {TEXT_CONTENT.carouselItems.map((item, index) => (
              <li key={index}>
                <div className={classnames('carousel', { 'carousel--linked': [ 0, 1 ].includes(index) })}>
                  <div className='carousel-tag'>
                    <span>{index + 1}.</span>
                    <DarkBoxWithGraph graph={item.graph} />
                  </div>
                  <p className='carousel-item__title'>{item.title}</p>
                  <p className='carousel-item__body'>{item.body}</p>
                </div>
              </li>
              ))}
          </ComoFuncionaAEntrada.ListView>
        </S.CarouselBox>
      </div>
      <div className='col'>
        <S.CtaBox>
          <ButtonWithDeepLink
            variant='orange-clary'
            renderDeepLinkWhen={width < WIDTH_MD}
            onClick={handleCtaClick}
            text={TEXT_CONTENT.cta}
            url={url}
          />
        </S.CtaBox>
      </div>
    </SectionComponent>
  )
}

ComoFuncionaAEntrada.ListView = ({ children }: {children: JSX.Element | JSX.Element[]}) => {
  const width = useWidth()
  if (width <= WIDTH_SM) {
    return (
      <DefaultCarousel
        sm={{ items: 1, partialVisibilityGutter: 80 }}
        md={{ items: 1, partialVisibilityGutter: 120 }}
        xl={{ items: 3, slidesToSlide: 1 }}
        notShowArrows
        notShowDots
      >
        {children}
      </DefaultCarousel>
    )
  }

  return <ul>{children}</ul>
}

export default withCommonSectionProps(ComoFuncionaAEntrada)
