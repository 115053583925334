import React from 'react'
import * as S from './styles'
import { CrediarioDigitalSectionProps } from '../../types'
import SectionComponent from 'src/components/SectionComponent'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { CrediarioDigitalH1 } from '../../styles'
import { secundaryBlue, secundaryPink, secundaryYellow } from 'src/styles/newColors'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { Button } from '@interco/inter-ui/components/Button'
import Picture from 'src/components/Picture'
import { ColoredTag } from '../components/_ColoredTag'
import { DarkBoxGraphs, DarkBoxWithGraph } from '../components/DarkBoxWithGraph/_DarkBoxWithGraph'
import { useQRCodeModal } from 'src/context/QRCodeModalContext/QRCodeModalContext'

type ParceleSuasComprasProps = CrediarioDigitalSectionProps

const TEXT_CONTENT = {
  heading: '<span class="text-orange--extra">Crediário Digital</span>: parcele suas compras em <span class="text-orange--extra">até 24x</span> no Shopping do Inter',
  coloredTags: [
    '100% online',
    'Não precisa do cartão de crédito',
    'Acompanhe as parcelas pelo Super App',
  ],
  body1: 'Ficou sem limite no cartão? Parcela no Crediário!',
  body2: 'Dá pra trocar o celular, garantir um eletrodoméstico novinho ou comprar o produto dos sonhos e pagar de um jeito que cabe no seu bolso.',
  cta: 'Comprar no Crediário Digital',
}

const ParceleSuasCompras: React.FC<ParceleSuasComprasProps> = ({ id, width, sendDataLayerEvent, dataLayerSection }: ParceleSuasComprasProps) => {
  const url = ' https://inter-co.onelink.me/Qyu7/0m9kefgw'
  // eslint-disable-next-line @typescript-eslint/typedef
  const { setIsModalOpen } = useQRCodeModal()
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })

    if (width >= WIDTH_MD) {
      setIsModalOpen(true)
    }
  }
  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '703px', md: '416px', lg: '562px', xl: '688px', xxl: '752px' }}
    >
      <div className='col-sm-12 col-md-6 order-sm-1 order-md-2'>
        <div className='pb-2 pb-md-3 text-center text-md-left'>
          <CrediarioDigitalH1>
            {TEXT_CONTENT.heading}
          </CrediarioDigitalH1>
        </div>
        <div className='pt-3 pb-4 pb-md-3 pt-lg-2 pt-xl-3'>
          <S.ColoredTagsWrapper className='mb-2'>
            <ColoredTag backgroundColor={secundaryYellow} borderRadius='30px' text={TEXT_CONTENT.coloredTags[0]} />
            <DarkBoxWithGraph graph={DarkBoxGraphs.money} />
            <ColoredTag backgroundColor={secundaryPink} borderRadius='8px' text={TEXT_CONTENT.coloredTags[1]} />
          </S.ColoredTagsWrapper>
          <S.ColoredTagsWrapper>
            <DarkBoxWithGraph graph={DarkBoxGraphs.phone} />
            <ColoredTag backgroundColor={secundaryBlue} borderRadius='30px' text={TEXT_CONTENT.coloredTags[2]} />
          </S.ColoredTagsWrapper>
        </div>
        <S.ParceleSuasComprasTextWrapper className='pt-lg-2 pb-lg-3 pt-xl-3'>
          <p className='mb-1 body-text'><strong>{TEXT_CONTENT.body1}</strong></p>
          <p className='body-text'>{TEXT_CONTENT.body2}</p>
        </S.ParceleSuasComprasTextWrapper>
        <div className='d-block mx-auto pt-lg-2 pt-xl-3 mb-3'>
          <Button fullWidth onClick={handleCtaClick} className='d-none d-md-block' style={{ paddingTop: 12 }}>
            {TEXT_CONTENT.cta}
          </Button>
          <Button as='a' fullWidth href={url} className='d-block d-md-none text-center text-white' style={{ paddingTop: 12 }}>
            {TEXT_CONTENT.cta}
          </Button>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 order-sm-2 order-md-1'>
        <div className='mb-3'>
          <Picture
            width='100%'
            minHeight={{ sm: '292px', md: '336px', lg: '434px', xl: '528px', xxl: '616px' }}
            alt='Mulher sorridente em pé enquanto usa o celular e compra em até 24x no Crediário Digital.'
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-hero/image.webp'
          />
        </div>
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(ParceleSuasCompras)
